@use '../variables' as vars;

.cover {
  display: flex;
  width: 100vw;
  height: 90vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 1rem 0;
    padding: 1rem;
    background-color: vars.$background-transparent;

    &-middle-text-container {
      display: flex;
      margin-top: 1rem;
      width: 300px;
    }

    &-visual-separator {
      width: 20%;
      display: flex;
      justify-content: center;
    }

    &-separator-bar {
      border-left: vars.$accent-color 1px solid;
    }

    &-text {
      width: 40%;
      text-transform: uppercase;
      color: vars.$accent-color;

      &__left {
        text-align: right;
      }

      &__right {
        text-align: left;
      }
    }
  }
}
