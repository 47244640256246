@use 'variables';
@use 'common/mixins';
@use "sass:meta";
@use "reset";

// fonts
* {
  font-family: 'Source Code Pro', monospace;
}

@include meta.load-css("variables/colors");
@include meta.load-css("variables/breakpoints");
@include meta.load-css("common/mixins");
@include meta.load-css("common/button");
@include meta.load-css("common/bullet");

// module
@include meta.load-css("cover/main");
@include meta.load-css("speakers/main");
@include meta.load-css("module/photos_section");
@include meta.load-css("module/talks_section");
@include meta.load-css("module/sponsors_section");
@include meta.load-css("module/tickets");
@include meta.load-css("module/code_of_conduct_section");

// Utils
@include meta.load-css("common/utils");

// layout
@include meta.load-css("layout/body");
@include meta.load-css("layout/footer");
