.tickets {
  display: flex;
  align-items: center;
  flex-flow: column;

  &__info {
    color: #fff;
  }

  .button {
    font-size: 20px !important;
    padding: 15px 20px !important;
    margin-top: 50px;
  }
}
