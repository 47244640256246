@import "reset";

// fonts
* {
  font-family: 'Source Code Pro', monospace;
}

@import "variables/colors";
@import "variables/breakpoints";
@import "common/mixins";
@import "common/button";
@import "common/bullet";

// module
@import "cover/main";
@import "speakers/main";
@import "module/photos_section";
@import "module/talks_section";
@import "module/sponsors_section";
@import "module/tickets";
@import "module/code_of_conduct_section";

// Utils
@import "common/utils";

// layout
@import "layout/body";
@import "layout/footer";
