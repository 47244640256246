@use '../variables' as vars;

.code-of-conduct_section {
  background: rgba(78, 72, 123, 0.90);
  margin: 2rem 0;
  padding: 1rem 0;
  width: 100%;

  &__title {
    color: vars.$accent-light-color;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }

  &__info {
    padding: 2rem;
    color: vars.$accent-light-color;

    a {
      background-color: vars.$accent-color-link;
      color: #2d2266;
      text-decoration: none;
    }
  }
}
