@use '../variables' as vars;

@mixin medium {
  @media (min-width: #{vars.$medium-width}) {
    @content;
  }
}

@mixin large {
  @media (min-width: #{vars.$large-width}) {
    @content;
  }
}
