@use '../variables' as vars;

.photos_section {
  margin-top: 2rem;
  background-color: vars.$background-transparent;

  &__grid {
    width:100%;
    height: 50vh;
    border:0;
  }
}
