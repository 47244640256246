@import "speaker";

.speakers {
  width: 100%;

  &__list {
    display: grid;
    grid-template-columns: minmax(160px, 1fr) repeat(auto-fill, minmax(160px, 1fr));
    grid-auto-rows: 1fr;
    grid-auto-flow: dense;
  }

  &__item {
    filter: sepia(1) brightness(50%) hue-rotate(270deg);

    &:hover {
      filter: initial;
    }

    &:target {
      filter: initial;
      grid-row: span 2;
      grid-column: span 2;

      @media (min-width: 480px) {
        grid-column: span 3;
      }

      .speaker {
        &__link {
          display: none;
        }

        &__card {
          display: block;
        }
      }
    }
  }
}
