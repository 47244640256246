.bullet {
  color: #000;
  padding: 2px 8px;
  border-radius: 11px;
  font-size: 10px;
  white-space: nowrap;

  &[type="TALK"] {
    background: #faa99d;
  }

  &[type="MINI_TALK"] {
    background: #fddf7e;
  }

  &[type="KEYNOTE"] {
    background: #9bfbe1;
  }

  &[type="BEGINNER"] {
    background: #67ebfa;
  }

  &[type="ADVANCED"] {
    background: #ce97fb;
  }
}
