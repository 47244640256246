.speaker {
  &__link {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__image {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
  }

  &__card {
    display: none;
    height: 100%;
    color: $accent-color;
    background-color: $background-color;
    border: 1px dashed $accent-color;

    &-avatar {
      float: left;
      width: 25%;
      object-fit: cover;
      margin: 1rem;
    }

    &-name {
      font-size: 1.5rem;
      margin: 1rem 1rem 0;
    }

    &-company {
      font-size: 1.2rem;
      margin: 0 1rem;
      color: $accent-purple;
    }

    &-bio {
      font-size: 1rem;
      margin: 1rem;
      hyphens: auto;
    }

    &-social {
      margin: 1rem;
      text-align: right;

      li {
        display: inline;

        &::before {
          content: "/ "
        }

        &:first-child::before {
          content: "";
        }
      }

      a {
        text-decoration: none;
        font-weight: bold;
        color: $accent-color;
        background-color: $accent-color-link;
      }
    }
  }
}
