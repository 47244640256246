.footer {
  background: #7b7afb;

  .utils__inner {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 50px;

    @include medium {
      flex-direction: row;
    }
  }

  &__copyright {
    text-align: center;
    background-color: #3d3d89;
    color: #fff;
    padding: 10px 0px;
    margin-top: 20px;
  }

  &__title {
    font-size: 30px;
    font-weight: 600;
    color: #261f4e;
    margin-bottom: 50px;
    margin-top: 40px;
    text-align: center;
  }

  &:before {
    content: '';
    width: 100%;
    height: 20px;
    display: block;
    margin-top: 90px;
    border: 10px dashed white;
  }
}

.staff {
  &__item {
    width: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    margin: 20px 0px;

    &_name {
      font-size: 20px;
      font-weight: 500;
    }

    &_info {
      font-size: 13px;
    }

    &_info a {
      font-size: 13px;
      background-color: #62ebf7;
      color: #2d2266;
      text-decoration: none;
    }

    &_pic {
      width: 60px;
      height: 60px;
      margin-right: 20px;
      background-size: cover;
      border-radius: 100%;
    }
  }
}
