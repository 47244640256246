@use '../variables' as vars;
@use '../common/mixins' as mixins;

.talks_section {
  width: 100%;
  background-image: linear-gradient(to right, #ceb4ef, #ad9df4, #8682f9);

  &__title {
    color: vars.$accent-light-color;
    background: #4e487b;
    font-size: 2rem;
    margin: 2rem 0;
    padding: 1.25rem 2.5rem;
    text-align: center;

    @include mixins.medium {
      text-align: right;
    }
  }

  &__day {
    margin: 4rem 0 1rem;
    text-align: center;
    font-size: xx-large;
    color: vars.$accent-color;
  }

  &__hour {
    display: flex;
    align-items: center;
    max-width: 100vw;
    border-bottom: 2px #4e487b dashed;

    &_label {
      margin: 0 2rem;
      color: vars.$accent-color;
    }
  }

  &__rail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 50%;
  }

  &__item {
    margin: 1rem;
    color: vars.$accent-light-color;

    &_abstract {
      font-size: 0.75rem;
      margin: 0.5rem 0;
      display: none;
    }

    &:target {
      .talks_section__item_abstract {
        display: block;
      }
    }

    &_speaker {
      color: #261f4e;
    }

    &_footer {
      font-size: 0.8rem;
      margin-top: 0.5rem;
    }

    a {
      text-decoration: none;
      color: vars.$accent-light-color;

      &:hover {
        text-decoration: underline;
      }

      &:active {
        color: vars.$accent-light-color;
      }

      &:visited {
        color: vars.$accent-light-color;
      }
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 auto;

    @include mixins.medium {
      flex-direction: row;
    }
  }

  &__lunch {
    margin: 35px 0px;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    color: #fff;

    hr {
      display: none;
      width: 100%;
      max-width: 100px;
      margin: 0px;
      border: none;
      border-bottom: 1px solid #fff;

      @include mixins.medium {
        display: initial;
      }
    }

    h4 {
      margin: 0 20px;
    }
  }

  &:after {
    content: '';
    width: 100%;
    height: 20px;
    display: block;
    margin-top: 90px;
    border: 10px dashed white;
  }
}
