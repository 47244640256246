@use '../variables' as vars;

.sponsors_section {
  margin: 2rem 0;
  padding: 1rem 0;
  background-color: vars.$background-transparent;

  .utils__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    color: vars.$accent-color;
  }

  &__group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin: 1em 0;

    img {
      flex: 0 0 0;
    }

    &-tier1 {
      img {
        min-width: 280px;
      }
    }

    &-tier2 {
      img {
        min-width: 220px;
      }
    }

    &-tier3 {
      img {
        min-width: 160px;
      }
    }
  }
}
