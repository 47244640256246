.button {
  &[type="DASHED"],
  &[type="CALL_TO_ACTION"] {
    border: 2px dashed #2d2266;
    background: #6a63ac;
    color: #ffff;
    font-size: 15px;
    text-decoration: none;
    padding: 5px 15px;
    opacity: .5;
    cursor: pointer;
    display: block;

    &[status="ACTIVE"] {
      opacity: 1;
    }

  }

  &[type="CALL_TO_ACTION"] {
    background-color: #62ebf7;
    color: #2d2266;
    opacity: 1;
    font-size: 30px;
    border-width: 5px;
    font-weight: 600;
    padding: 30px;
  }
}
